// @flow
import * as React from 'react'
import styled from 'styled-components'
import { config } from 'react-awesome-styled-grid'
import { Navigation, Pagination, A11y, EffectFade } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { H3, P, colors } from './shared'
import { LeftSliderArrow } from './shared/icons/LeftSliderArrow'
import { RightSliderArrow } from './shared/icons/RightSliderArrow'
import { OutlineOnFocusVisible } from './shared/buttons'
import { isChromeForIOs145 } from '@helpers/utils'

type PropsType = {
  title: string,
  backgroundColor?: string
}

const userData = [
  {
    key: 'ELLIE',
    text: `"Dipsea is unparalleled when it comes to the production value of their audiobooks and their focus on storytelling, and I love that they have multiple voice actors to play each part!"`,
    name: `ELLIE`
  },
  {
    key: 'ANISHA',
    text: `"Really refreshing take on romance. I love the realism of the stories. Great storytelling, voice acting, and sound design. Well done!"`,
    name: `ANISHA`
  },
  {
    key: 'RACHEL',
    text: `"The narrators are outstanding! The accents are outstanding! The heat factor is volcanic—just off the charts."`,
    name: `RACHEL`
  },
  {
    key: 'MAYA',
    text: `"This app has so many story genres, whether you’re into fantasy, cowboys, or just sweet and flirty. Definitely a lot of good storytelling by talented writers and voice actors!"`,
    name: `MAYA`
  },
  {
    key: 'JAHNAVI',
    text: `"I love how varied and queer-friendly this app is. The characters feel like real, interesting people and that’s what makes them so compelling."`,
    name: `JAHNAVI`
  },
  {
    key: 'CAYLEE',
    text: `"Smart, subtle story details that let you know these stories were written by women. Great build-ups and story arcs. Plus the app is well-designed and easy to navigate. Highly recommend!"`,
    name: `CAYLEE`
  },
  {
    key: 'LEAH',
    text: `"I love this app so much. It’s inclusive, with many options for different genders, orientations, scenarios, and interests. Honestly their audiobooks are fun and refreshing and it’s firmly one of the best apps out there for femme audiences."`,
    name: `LEAH`
  }
]

export default function UserQuotesSlider ({
  title,
  backgroundColor
}: PropsType): React.Node {
  const slides = (): React.Node => {
    return userData.map(
      (
        item: { key: string, text: string, name: string },
        index: number
      ): React.Node => {
        return (
          <SwiperSlide key={`user-quote-slider-${item.key}`}>
            <QuoteItem backgroundColor={backgroundColor}>
              <Stars />
              <TextContainer>
                <QuoteText>{item.text}</QuoteText>
              </TextContainer>
              <QuoteTextAttr>{item.name}</QuoteTextAttr>
            </QuoteItem>
          </SwiperSlide>
        )
      }
    )
  }

  return (
    <ContainerDiv>
      <MediaHeader>{title}</MediaHeader>

      <Wrapper>
        <ArrowWrapper className="prev-media-slider top-left">
          <LeftSliderArrow color={colors.sand} />
        </ArrowWrapper>

        <Swiper
          modules={[Navigation, Pagination, A11y, EffectFade]}
          pagination={{
            clickable: true,
            type: 'bullets'
          }}
          draggable={true}
          navigation={{
            prevEl: '.prev-media-slider',
            nextEl: '.next-media-slider'
          }}
          breakpoints={{
            // $FlowFixMe
            100: {
              slidesPerView: 1,
              slidesPerGroup: 1,
              centeredSlides: true,
              spaceBetween: 20
            },
            // $FlowFixMe
            768: {
              slidesPerView: 2,
              slidesPerGroup: 2,
              centeredSlides: false,
              spaceBetween: 20
            },
            // $FlowFixMe
            1440: {
              slidesPerView: 3,
              slidesPerGroup: 3,
              centeredSlides: false,
              spaceBetween: 33
            }
          }}
          cssMode={isChromeForIOs145()}
        >
          {slides()}
        </Swiper>

        <ArrowWrapper className="prev-media-slider bottom-left">
          <LeftSliderArrow color={colors.sand} />
        </ArrowWrapper>

        <ArrowWrapper className="next-media-slider">
          <RightSliderArrow color={colors.sand} />
        </ArrowWrapper>
      </Wrapper>
    </ContainerDiv>
  )
}

const StarSvg = (): React.Node => (
  <svg
    width="20"
    height="19"
    viewBox="0 0 20 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ marginRight: '7px' }}
  >
    <path
      d="M10 0.000244141L12.8214 6.11696L19.5106 6.91007L14.5651 11.4835L15.8779 18.0904L10 14.8002L4.12215 18.0904L5.43493 11.4835L0.489435 6.91007L7.17863 6.11696L10 0.000244141Z"
      fill="#E3D0C5"
    />
  </svg>
)

export const Stars = (): React.Node => {
  return (
    <StarContainer>
      <StarSvg />
      <StarSvg />
      <StarSvg />
      <StarSvg />
      <StarSvg />
    </StarContainer>
  )
}

const QuoteTextAttr = styled(P)`
  font-size: 12px;
  text-align: center;
  color: ${colors.sand};
  text-transform: uppercase;
`
const StarContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
`

const ContainerDiv = styled.div`
  margin: 0 10px;
  ${(props: any): string => config(props).media.sm`
    margin: 0 30px 60px;
  `}

  .swiper-slide {
    height: auto;
  }
  .swiper {
    padding-bottom: 40px;
    margin-bottom: 70px;
    ${(props: any): string => config(props).media.md`
      padding-bottom: 80px;
      margin-bottom: 0px;
  `}
    
  }
  .swiper-pagination {
    height: 30px;
    bottom: 0px !important;
  }
  .swiper-pagination-bullet {
    height: 11px;
    width: 11px;
    margin: 0 6px !important;
    background-color: transparent !important;
    border: 1px solid ${colors.sand} !important;
    opacity: 1 !important;
    :hover {
      opacity: 0.8 !important;
    }
    ${OutlineOnFocusVisible};
  }
  .swiper-pagination-bullet-active {
    background-color: ${colors.sand} !important;
    border: 1px solid ${colors.sand} !important;
  }
  .prev-media-slider {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 1;
    @media only screen and (min-width: 1440px) {
      position: static;
      margin: 0 0 80px;
    }
    :focus {
      outline: none;
    }
  }
  .next-media-slider {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 1;
    @media only screen and (min-width: 1440px) {
      position: static;
      margin: 0 0 80px;
    }
    :focus {
      outline: none;
    }
  }
`
const Wrapper = styled.div`
  position: relative;
  margin: 0 0 0;
  @media only screen and (min-width: 1440px) {
    margin: 0 50px 0;
    display: flex;
  }
`
const MediaHeader = styled(H3)`
  white-space: pre-line;
  color: ${colors.sand};
  font-size: 36px;
  line-height: 120%;
  text-align: center;
  margin-bottom: 38px;
  ${(props: any): string => config(props).media.lg`
    font-size: 45px;
    margin-bottom: 58px;
  `}
`
const QuoteItem = styled.div`
  border-radius: 10px;
  background-color: ${(props: { backgroundColor: string }): string =>
    props.backgroundColor || colors.midnightBlue};
  height: calc(100% - 80px);
  padding: 30px 16px;
  margin: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${(props: any): string => config(props).media.md`
    padding: 35px 24px;
    margin: 0 10px;
  `}
  ${(props: any): string => config(props).media.lg`
    margin: 0;
  `}
`
const QuoteText = styled(P)`
  text-align: center;
  color: ${colors.sand};
`
const TextContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: 12px 0;
  min-height: 100px;
  max-width: 450px;
`
const ArrowWrapper = styled.div`
  display: none;

  ${(props: any): string => config(props).media.md`
    display: flex;
    padding: 0 10px;
    justify-content: center;
    align-items: center;
    will-change: transform;
    transition: transform 0.2s ease;
    transform: translate3d(0px, 0, 0);
    cursor: pointer;
    border-radius: 5px;
    ${OutlineOnFocusVisible};
    &.swiper-button-disabled {
      &:hover {
        transform: translate3d(0, 0, 0) !important;
      }
      > svg {
        opacity: 0.5 !important;
      }
    }
    &.prev-media-slider {
      margin-right: 30px;
      &:hover {
        transform: translate3d(-15px, 0, 0);
      }
    }
    &.next-media-slider {
      margin-left: 30px;
      &:hover {
        transform: translate3d(15px, 0, 0);
      }
    }
    &.top-left{
      display:none;
    }
  `}

  @media only screen and (min-width: 1440px) {
    padding: 0 20px;
    &.top-left {
      display: flex;
    }
    &.bottom-left {
      display: none;
    }
  }
`
