// @flow
import * as React from 'react'
import { colors } from '../colors'

export const LeftSliderArrow = (props: {
  color?: string,
  size?: number
}): React.Node => {
  const color = props.color || colors.midnightBlue
  const size = props.size || 39

  return (
    <svg width={size} height={size} viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg" style={{
      transform: 'rotate(180deg)'
    }}>
      <line x1="8.74228e-08" y1="19" x2="39" y2="19" stroke={color}/>
      <path d="M39 19C31.268 19 25 12.2843 25 4" stroke={color}/>
      <path d="M39 19C31.268 19 25 25.268 25 33" stroke={color}/>
    </svg>
  )
}
